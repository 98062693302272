.t {
  transform: none;
}
[home-news] {
  background-color: #000;
  position: relative;
}
[home-news] li {
  color: #fff;
  font-size: 1.25rem;
  padding: 0.625rem;
}
[home-news] li + li {
  border-top: 1px solid #fff;
}
