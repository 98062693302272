.t {
  transform: none;
}
[home-other] {
  background-color: #000;
  position: relative;
}
[home-other] li {
  color: #fff;
  font-size: 1.25rem;
  padding: 0.625rem;
}
[home-other] li + li {
  border-top: 1px solid #fff;
}
