.t {
  transform: none;
}
[scroll-menu] {
  z-index: 9;
}
[scroll-menu] .swiper-container {
  overflow: visible;
  width: auto;
}
[scroll-menu] .swiper-slide {
  width: auto;
  font-weight: 500;
  cursor: pointer;
}
[scroll-menu] .swiper-slide + .swiper-slide {
  margin-left: 1rem;
}
[scroll-menu] .plain {
  display: none;
}
@media (min-width: 768px) {
  [scroll-menu] {
    width: 70%;
    max-width: 87.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  [scroll-menu] .swiper-wrapper {
    display: none;
  }
  [scroll-menu] .swiper-wrapper .swiper-slide {
    display: inline-block;
  }
  [scroll-menu] .plain {
    display: block;
  }
}
