.t {
  transform: none;
}
[parallax-test] h1 {
  text-align: center;
}
[parallax-test] h2 {
  position: absolute;
  left: 0rem;
  bottom: 6.25rem;
  right: auto;
  top: auto;
  width: 100%;
  text-align: center;
}
[parallax-test] .s1 {
  height: calc(100vh + 500px);
  background-color: #666666;
  box-sizing: border-box;
  position: relative;
}
[parallax-test] .s1 .screen {
  padding-top: 6.25rem;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100vh;
}
[parallax-test] .s1 .screen p {
  text-align: center;
}
[parallax-test] .s2 {
  height: calc(100vh + 500px);
  background-color: #ff4800;
  padding-top: 6.25rem;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
[parallax-test] .s2 a {
  position: absolute;
  right: 1.25rem;
  bottom: 1.25rem;
  left: auto;
  top: auto;
}
[parallax-test] .s2[data-i='+'] h1,
[parallax-test] .s2[data-i='11'] h1,
[parallax-test] .s2[data-i='10'] h1,
[parallax-test] .s2[data-i='9'] h1,
[parallax-test] .s2[data-i='8'] h1,
[parallax-test] .s2[data-i='7'] h1,
[parallax-test] .s2[data-i='6'] h1,
[parallax-test] .s2[data-i='5'] h1 {
  color: white;
}
[parallax-test] .s3 {
  height: 200vh;
  background-color: #aaaaaa;
  position: relative;
  box-sizing: border-box;
}
[parallax-test] .s3 .screen {
  width: 100%;
  height: 100vh;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  padding-top: 12.5rem;
  overflow: hidden;
}
[parallax-test] .s3 .screen h1 {
  cursor: pointer;
}
[parallax-test] .s3[data-p='+'] h1,
[parallax-test] .s3[data-p='11'] h1,
[parallax-test] .s3[data-p='10'] h1,
[parallax-test] .s3[data-p='9'] h1,
[parallax-test] .s3[data-p='8'] h1,
[parallax-test] .s3[data-p='7'] h1,
[parallax-test] .s3[data-p='6'] h1,
[parallax-test] .s3[data-p='5'] h1 {
  color: white;
}
[parallax-test] .s4 {
  height: calc(100vh + 500px);
  background-color: #0048ff;
  position: relative;
}
